import React from 'react'
import { Table } from '@imcva/reactstrap-table'
import { columns } from './columns'
import { useAverageTimeToFill } from 'src/queries'
import { useDashboardState } from '../useDashboardState'
import { AverageTimeToFillQueryVariables } from 'src/gql/graphql'

const Body: React.FC = (props) => {
  const { start, end } = useDashboardState()
  const groupBy: AverageTimeToFillQueryVariables['groupBy'] = {
    from: 'cat_contracts',
    path: 'contract',
    group: 'contract._id',
    value: 'contract.abbreviation'
  }
  const data = useAverageTimeToFill({ start, end, groupBy })
  const groups = data.groups ?? []
  return (
    <Table storageKey='dashboard-avg-by-contract' options={{ data: groups, columns, initialState: { sorting: [{ id: 'contract', desc: false }] } }} />
  )
}

export default Body
