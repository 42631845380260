import { graphql } from 'src/gql'
import { ContractDetailsByIdQueryVariables } from 'src/gql/graphql'
import { GetTData } from 'src/gql/helpers'
import { useGraphQL, UseGraphqlOptions } from 'src/hooks/useGraphQL'

const document = graphql(/* graphql */`
  query contractDetailsById($id: MongoID!) {
    cat {
      contract {
        byId(_id: $id) {
          _id
          name
          abbreviation
          teamSize
          status
          prime
          subcontractor
          _status {
            _id
            name
          }
          portfolio_manager
          _portfolio_manager {
            _id
            name
          }
          program_manager
          _program_manager {
            _id
            name
          }
          primary_address {
            street
            city
            state
            zip
          }
        }
      }
    }
  }
`)

type TData = GetTData<typeof document>
export type ContractDetailsById = NonNullable<NonNullable<NonNullable<NonNullable<TData>['cat']>['contract']>['byId']>

const useContractDetailsById = (id: string, variables?: ContractDetailsByIdQueryVariables, options?: UseGraphqlOptions<TData>, enabled: boolean = true): ContractDetailsById | undefined => {
  const internalVariables: ContractDetailsByIdQueryVariables = Object.assign({
    id
  }, variables)
  const { data } = useGraphQL(document, internalVariables, options)
  return data?.cat?.contract?.byId ?? undefined
}

export { useContractDetailsById, document as contractDetailsByIdDocument }
