import { Formik } from 'formik'
import React from 'react'
import { UseMutateContractByIdReturn, contractDetailsByIdDocument, useMutateContractById, useContractDetailsById } from 'src/queries'
import Form from './Form'
import { useInvalidateQueryByDocument } from 'src/hooks/useGraphQL'
import useLookups from 'src/pages/cat/contracts/Contract/cards/Details/EditModal/useLookups'
import { MutateContractByIdMutation } from 'src/gql/graphql'
import { emptyStringToNull } from 'src/helpers/formHelpers'
import { pick } from 'lodash'

export type Values = Parameters<UseMutateContractByIdReturn['mutate']>[0]['record']

interface FormContextProps {
  id: string
  toggle: () => void
}

const FormContext: React.FC<FormContextProps> = (props) => {
  const { id, toggle } = props
  const contract = useContractDetailsById(id)
  if (contract === undefined) {
    throw new Error('Could not load contract!')
  }
  const lookups = useLookups()
  const update = useMutateContractById()
  const initialState: Values = pick(contract, [
    'name',
    'abbreviation',
    'portfolio_manager',
    'program_manager',
    'teamSize',
    'primary_address',
    'prime',
    'subcontractor'
  ])
  const invalidateContract = useInvalidateQueryByDocument(contractDetailsByIdDocument)
  const handleSuccess = (data: MutateContractByIdMutation): void => {
    const id = data.cat?.contract?.updateById?.recordId
    if (typeof id === 'string') {
      void invalidateContract({ id })
    }
    toggle()
  }
  return (
    <Formik
      initialValues={initialState}
      onSubmit={async (data) => {
        const record = { ...data }
        emptyStringToNull(record, 'portfolio_manager')
        emptyStringToNull(record, 'program_manager')

        update.mutate({ id, record }, { onSuccess: handleSuccess })
      }}
    >
      {(props) => (
        <Form lookups={lookups} toggle={toggle} isLoading={update.isLoading} hasError={update.isError} />
      )}
    </Formik>
  )
}

export default FormContext
