import { UseMutationResult } from '@tanstack/react-query'
import { graphql } from 'src/gql'
import { MutateRemoveDistributionByIdMutation, MutateRemoveDistributionByIdMutationVariables } from 'src/gql/graphql'
import { useGraphMutation } from 'src/hooks/useGraphMutation'

const document = graphql(/* graphql */`
  mutation mutateRemoveDistributionById($id: MongoID!) {
    cat {
      distribution {
        removeById(_id: $id) {
          recordId
        }
      }
    }
  }
`)

export type UseMutateRemoveDistributionByIdReturn = UseMutationResult<
MutateRemoveDistributionByIdMutation,
unknown,
MutateRemoveDistributionByIdMutationVariables
>

const useMutateRemoveDistributionById = (): UseMutateRemoveDistributionByIdReturn => {
  return useGraphMutation(document)
}

export { useMutateRemoveDistributionById, document as mutateRemoveDistributionByIdDocument }
