import React from 'react'
import { Col, Row } from 'reactstrap'
import DatePicker from './DatePicker'
import { useDashboardState } from 'src/pages/cat/view/dashboard/useDashboardState'
import Card from 'src/components/Card'
import { faArrowRotateRight } from '@fortawesome/free-solid-svg-icons'

const DashboardDates: React.FC = () => {
  const state = useDashboardState()

  const handleStartChange = state.setStart
  const handleEndChange = state.setEnd

  return (
    <Card
      title='Dashboard'
      secondaryActions={[{
        key: 'reset',
        text: 'Reset Dates',
        icon: faArrowRotateRight,
        onClick: state.reset
      }]}
    >
      <Row>
        <Col md={6}>
          <DatePicker
            label='Start Date'
            name='startDate'
            value={state.start}
            onChange={handleStartChange}
          />
        </Col>
        <Col md={6}>
          <DatePicker
            label='End Date'
            name='endDate'
            value={state.end}
            onChange={handleEndChange}
          />
        </Col>
      </Row>
    </Card>
  )
}

export default DashboardDates
