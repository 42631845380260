import React, { Suspense } from 'react'
import Body from './Body'
import { Col, ModalHeader } from 'reactstrap'
import Modal from 'src/components/theme/Models/Modal'

interface DeleteModalProps {
  isOpen: boolean
  toggle: () => void
  id: string
}

const DeleteModal: React.FC<DeleteModalProps> = (props) => {
  const { isOpen, toggle, id } = props

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      title='Delete Distribution'
    >
      <ModalHeader>Delete Distribution</ModalHeader>
      <Suspense fallback={<Col className='col-12 placeholder' data-testid='placeholder' />}>
        <Body id={id} toggle={toggle} />
      </Suspense>
    </Modal>
  )
}

export default DeleteModal
