import { faSave, faSpinner } from '@fortawesome/free-solid-svg-icons'
import { Col, Row } from 'reactstrap'
import { useFormikContext } from 'formik'
import React from 'react'
import { Dropdown, Input } from 'src/components/Forms'
import { Body, Footer } from 'src/components/theme/Models/Modal'
import { UseLookupsReturn } from './useLookups'
import { Values } from './FormContext'
import YesNo from 'src/components/Forms/YesNo'

interface FormProps {
  toggle: () => void
  isLoading?: boolean
  hasError?: boolean
  disableSave?: boolean
  lookups?: UseLookupsReturn
}

const Form: React.FC<FormProps> = (props) => {
  const { hasError, toggle, isLoading, disableSave } = props
  const {
    portfolioManagers,
    programManagers
  } = props.lookups ?? {}
  const { handleSubmit } = useFormikContext<Values>()
  return (
    <>
      <Body error={{
        error: 'There was an issue updating the position details.',
        show: hasError ?? false
      }}
      >
        <Input type='text' name='name' label='Name' />
        <Input type='text' name='abbreviation' label='Abbreviation' />
        <Dropdown name='portfolio_manager' label='Portfolio Manager' options={portfolioManagers ?? []} />
        <Dropdown name='program_manager' label='PgM/PjM' options={programManagers ?? []} />
        <Input type='number' name='teamSize' label='Team Size' />
        <YesNo name='prime' label='Prime' />
        <Input type='text' name='subcontractor' label='Subcontractor' />
        <Input type='text' name='primary_address.street' label='Primary Address' />
        <Row>
          <Col sm={12} md={6}>
            <Input type='text' name='primary_address.city' label='City' />
          </Col>
          <Col sm={12} md={3}>
            <Input type='text' name='primary_address.state' label='State' maxLength={2} />
          </Col>
          <Col sm={12} md={3}>
            <Input type='text' name='primary_address.zip' label='Zip' maxLength={5} />
          </Col>
        </Row>
      </Body>
      <Footer
        toggle={toggle}
        onAction={handleSubmit}
        action={{
          text: 'Save',
          icon: isLoading === true ? faSpinner : faSave,
          color: 'primary',
          disabled: disableSave === true || isLoading
        }}
      />
    </>
  )
}

export default Form
