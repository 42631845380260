import { createColumnHelper } from '@tanstack/react-table'
import { AverageTimeToFill } from 'src/queries'

type TData = NonNullable<AverageTimeToFill['groups']>[0]

const columnHelper = createColumnHelper<TData>()

const columns = [
  columnHelper.accessor('group', {
    id: 'contract',
    header: 'Contract'
  }),
  columnHelper.accessor('avg', {
    header: 'Average'
  })
]

export { columns }
