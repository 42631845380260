import React from 'react'
import { useNavigate, useLocation, Link } from 'react-router-dom'
import {
  useDistributionDetailsById,
  useMutateRemoveDistributionById,
  useCandidates
} from 'src/queries'
import { Button, ModalBody, ModalFooter } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/free-solid-svg-icons'

interface BodyProps {
  id: string
  toggle: () => void
}

const Body: React.FC<BodyProps> = (props) => {
  const { id, toggle } = props
  const distribution = useDistributionDetailsById(id)
  const candidates = useCandidates({
    distribution: id
  }, typeof id === 'string')
  const canDelete = candidates.length === 0
  const mutateRemove = useMutateRemoveDistributionById()
  const nav = useNavigate()
  const location = useLocation()
  const handleRemove = (): void => {
    mutateRemove.mutate({ id }, {
      onSuccess: () => {
        if (location.key === 'default') {
          nav('/')
        } else {
          nav(-1)
        }
      }
    })
  }
  return (
    <>
      <ModalBody>
        {canDelete
          ? (
            <p className='text-center'>
              Are you sure you want to delete <b>{distribution?._subcategory?.name}</b>?
            </p>
            )
          : (
            <>
              <p className='text-center'>
                <b>{distribution?._subcategory?.name}</b> cannot be deleted because it has the following candidates:
              </p>
              <div className='d-flex justify-content-center'>
                <ul>
                  {candidates.map((candidate) => {
                    const name = [candidate?.name?.last, candidate?.name?.first].filter(v => typeof v === 'string').join(', ')
                    return (
                      <li key={candidate._id}>
                        <Link to={`/cat/candidates/${String(candidate._id)}`} target='_blank' rel='noopener noreferrer'>
                          {name}
                        </Link>
                      </li>
                    )
                  })}
                </ul>
              </div>
            </>
            )}
      </ModalBody>
      <ModalFooter>
        <Button color='secondary' onClick={toggle}>
          Cancel
        </Button>
        <Button color='danger' disabled={!canDelete} onClick={handleRemove}>
          <FontAwesomeIcon icon={faTrash} /> Delete
        </Button>
      </ModalFooter>
    </>
  )
}

export default Body
