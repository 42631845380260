import React from 'react'

import { DescriptionList, Item, ItemProps } from 'src/components/DescriptionList'
import FormatAddress from 'src/components/FormatAddress'
import { yesno } from 'src/helpers/displayhelpers'
import { ContractDetailsById } from 'src/queries'

interface ListProps {
  contract: ContractDetailsById | undefined
}

const List: React.FC<ListProps> = (props) => {
  const { contract } = props
  const primaryAddress = contract?.primary_address ?? {}
  const section: Array<Omit<ItemProps, 'id'>> = [
    { title: 'Name', value: contract?.name },
    { title: 'Abbreviation', value: contract?.abbreviation },
    { title: 'Portfolio Manager', value: contract?._portfolio_manager?.name },
    { title: 'PgM/PjM', value: contract?._program_manager?.name },
    { title: 'Team Size', value: contract?.teamSize },
    { title: 'Prime', value: yesno(contract?.prime) },
    { title: 'Subcontractor', value: contract?.subcontractor },
    { title: 'Primary Address', value: (<FormatAddress {...primaryAddress} />) }
  ]
  const dlProps = {
    className: 'row-cols-1',
    suspended: contract === undefined,
    titleProps: { lg: 'auto' },
    valueProps: { lg: 'auto', className: 'mb-lg-0 mb-md-1 mb-3' }
  }
  return (
    <DescriptionList {...dlProps}>
      {section.map(item => {
        const key = item.title.toLowerCase().replace(' ', '-')
        return <Item {...item} key={key} id={key} />
      })}
    </DescriptionList>
  )
}

export default List
